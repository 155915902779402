import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchpedidoPipe } from './searchpedido.pipe';
import { SearchProductsPipe } from './search-products.pipe';



@NgModule({
  declarations: [SearchpedidoPipe, SearchProductsPipe],
  imports: [
    CommonModule
  ],
  exports: [
    SearchpedidoPipe, SearchProductsPipe
  ]
})
export class PipesModule { }
