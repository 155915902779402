export class StoreEntity {

  id: number;
  direccion: string;
  postcode: string;
  zipcode: string;
  telefono: string;
  user_id: number;
  categoria_id: number;
  city: string;
  state: string;
  formulario_pedido: boolean;
  open_close: string;
  logo: string;
  push_deals: string;
  push_order: string;
  delivery: boolean;
  precio_delivery: number;
  tipo_store: string;

}
