import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StoreEntity } from '../models/store.entity';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  urlapi = environment.urlapi;


  constructor(private http: HttpClient) { }


  // actualizar store
  update(store: StoreEntity) {
    return this.http.post(this.urlapi + 'store/update/' + store.id, store).pipe(
      map(data => data as StoreEntity)
    );
  }

  // get store
  getStore(id: number) {
    return this.http.get(this.urlapi + 'store/getstore/' + id).pipe(
      map(data => data as StoreEntity)
    );
  }

  // actualizar el estado del delivery
  updateDelivery(estado: number, store_id: number) {
    return this.http.get(this.urlapi + 'store/updatedelivery/' + estado + '/' + store_id).pipe(
      map(data => data as StoreEntity)
    );
  }

  updatePriceDelivery(precio: number, store_id: number) {
    return this.http.get(this.urlapi + 'store/updatepricedelivery/' + precio + '/' + store_id).pipe(
      map(data => data as StoreEntity)
    );
  }


  updateLogo(store: number, logo: string) {
    return this.http.get(this.urlapi + 'store/updatelogo/' + store + '/' + logo).pipe(
      map(data => data as any)
    );
  }

}
