import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { UserStoreEntity } from 'src/app/models/user_store.entity';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.page.html',
  styleUrls: ['./reset.page.scss'],
})
export class ResetPage implements OnInit {

  login_frm: FormGroup;
  userstore: any;

  constructor(private route: Router, 
    private loadctr: LoadingController,
    private toastctr: ToastController,
    private userservice: UserService) { 
    this.login_frm = new FormGroup({
      'password': new FormControl('',Validators.required),
      'passwordConfirm': new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.userstore = JSON.parse(localStorage.getItem('userstore'));
  }

  // success toast
  async successToast() {
    const toast = await this.toastctr.create({
      message: 'Success.',
      color: 'success',
      duration: 2000
    });
    toast.present();
  }

  async errorToast() {
    const toast = await this.toastctr.create({
      message: 'Password invalid.',
      color: 'danger',
      duration: 2000
    });
    toast.present();
  }

  async errorPass() {
    const toast = await this.toastctr.create({
      message: 'Passwords do not match.',
      color: 'danger',
      duration: 2000
    });
    toast.present();
  }

  //loader
  async loader() {
    const loading = await this.loadctr.create({
      cssClass: 'my-custom-class',
      message: 'validating wait...',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  // metodo para login
  login() {
    this.loader();

    const password = this.login_frm.controls['password'].value;
    const passwordConfirm = this.login_frm.controls['passwordConfirm'].value;
    
    if (password === passwordConfirm) {
      this.userservice.changePassword(this.userstore.user_id, passwordConfirm).then(response => {
        this.successToast();
      }, err => {
        this.errorToast();
      });
    } else {
      this.errorPass();
    }
   
  }

}
