import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProducts'
})
export class SearchProductsPipe implements PipeTransform {

  transform(arreglo: any[], texto: string = ''): any {
    if (texto === '') {
      return arreglo;
    }

    if (!arreglo) {
      return arreglo
    }

    return arreglo.filter(
      item => JSON.stringify(item).toLocaleLowerCase().includes(texto)
    );
  }

}
